import classNames from "classnames/bind";
import Image from "next/image";
import styles from "./404.module.scss";

const cx = classNames.bind(styles);

export default function FourZeroFour() {
  return (
    <div className={cx("container")}>
      <div className={cx("image")}>
        <Image src="/404.png" layout="fill" />
      </div>
    </div>
  );
}
